
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter, Mutation, namespace } from 'vuex-class';
import { showServerError } from '@/utils';
import PageLayout from "@/components/layout/PageLayout.vue";
import { eventBus } from "@/eventbus";
import PageTabs from "@/components/base/PageTabs.vue";
import Status from "@/components/base/Status.vue";
import DownloadBtn from "@/components/base/buttons/DownloadBtn.vue";
import { IUserV3 } from "@/api/types/user";
import { getRoles } from "@/api/auth";
import { getUserEntityV3, getUserV3 } from "@/api/user";
import UserMain from '@/components/administration/users/UserMain.vue';
import UserTokenTab from '@/components/administration/users/UserTokenTab.vue';
import { MESSENGER } from '@/mappings/messengers';
import router from "@/router";
import { IUserData } from "@/store/modules/administration/types";

const admin = namespace('administrationModule');

@Component({
    components: {
        DownloadBtn,
        Status,
        PageTabs,
        PageLayout,
    },
})

export default class User extends Vue {
    @Mutation('SET_DEFAULT_FILTERS_PARAMS_OBJECT') setDefaultParamsObject;
    @Mutation('SET_FILTERS_PARAMS_OBJECT') setFiltersParamsObject;
    @Getter('GET_FILTERS_PARAMS_OBJECT') getFiltersParamsObject;
    @Getter('GET_DEFAULT_FILTERS_PARAMS_OBJECT') getDefaultFiltersParamsObject;

    @admin.State('userData') userData!: IUserData;
    @admin.Mutation('setUserData') setUserData!: (payload: {}) => void;

    user = {} as IUserV3;
    tabItems = [
        { tab: 'Основное', component: UserMain, class: 'tab-main' },
        { tab: 'Токены', component: UserTokenTab, class: 'tab-token' },
    ];
    tab: number = 0;
    isShowUser = false;

    get isShowBodyComponent(): boolean {
        return Object.keys(this.user).length > 0 && this.isShowUser;
    }

    get bodyComponent(): any {
        return this.tabItems[this.tab].component;
    }

    get tabClass(): string {
        return this.tabItems[this.tab].class;
    }

    get title(): string {
        return `${this.user.account_id || this.user.id} ${this.user.first_name} ${this.user.last_name}`;
    }

    async created(): Promise<void> {
        this.setDefaultParamsObject({});
        this.setFiltersParamsObject({});
        await this.getUser();
        document.title = this.title;
        this.tab = Number(this.$route.query.tab!) || 0;
        this.changeTab(this.tab, true);
        eventBus.$on("update-user", this.getUser);
    }

    async getUser(): Promise<void> {
        try {
            await this.getUserData();
            this.user.available = await getRoles();
            document.title = `${this.user.account_id} ${this.user.first_name} ${this.user.last_name}`;
        } catch (err) {
            showServerError(err, 'Пользователь не найден');
        } finally {
            this.isShowUser = true;
        }
    }

    changeTab(tab: number, isHistory: boolean): void {
        this.tab = tab;
        if (isHistory) {
            this.setTabToUrl(this.$route.query);
        }
    }

    @Watch('$route.query')
    setTabToUrl(query?: object): void {
        if (this.$route.query.hasOwnProperty('tab') && Number(this.$route.query.tab!) === this.tab) {
            return;
        }
        const params = { ...query, ...{ tab: this.tab } };
        this.updateQueryString(params);

    }

    updateQueryString(data: any): void {
        const query = { ...data };
        router.replace({ name: router.currentRoute.name || '', query }).catch((error) => {
            if (error.name !== 'NavigationDuplicated') {
                throw error;
            }
        });
    }

    beforeDestroy(): void {
        eventBus.$off("update-user", this.getUser);
        this.setUserData({});
    }

    async getUserData(): Promise<void> {
        const accountType = this.userData.accountType;
        const isEmployeeOrAffiliate = ['employee', 'affiliate'].includes(accountType);
        if (isEmployeeOrAffiliate) {
            this.user = await getUserV3(Number(this.$route.params.id), accountType as 'employee' | 'affiliate');
        } else {
            const id = this.userData.id.toString();
            this.user = await getUserEntityV3(id);
        }
        this.user.account_type = accountType;
        this.user.is_two_factor_enabled = this.userData.isTwoFactorEnabled ? 1 : 0;
        if (this.user.messengers?.length === 0) {
            this.user.messengers = [
                { type: MESSENGER.TELEGRAM, account: '' },
                { type: MESSENGER.WHATSAPP, account: '' },
                { type: MESSENGER.SKYPE, account: '' },
            ];
        }
    }
}
