import { ICreateOffer } from "@/api/types/offers";
import {
    createEmployeesOffer,
    getEmployeesDepartments,
    getEmployeesOfferVerticals, getEmployeesPayouts,
    getEmployeesProducts, getEmployeesStats, getEmployeesTargetActions,
    getEmployeesTraffics, getOfferTrackingDomainsList, getOfferTrackingList, getOneEmployeesOffer,
} from "@/api/offers";
import { showNotification, showServerError } from "@/utils";
import { getAdvertisersSearch } from "@/api/revenue";
import { IAdvertiser } from "@/api/types/revenue";
import { IBaseAutocomplete } from "@/types";
import store from "@/store";

export enum OFFER_FIELD {
    TEXT = 'text',
    MULTILINE_TEXT = 'multiline_text',
    MONEY = 'money',
    TAG_SELECT = 'tag',
    SELECT = 'select',
    AUTOCOMPLETE = 'autocomplete',
    SWITCH = 'switch',
    DATE = 'date',
    COOKIES = 'cookies',
    USER_COOKIES = 'user_cookies',
    GEO = 'geo'
}

export interface IOfferFiled {
    text: string;
    property: string;
    type: OFFER_FIELD;
    validateRule?: (v: any) => boolean | string;
    isWithDefVal?: boolean;
}

export const CREATE_OFFER_VALIDATE_RULES = {
    TEXT: (v: string): boolean => !!v && v.length > 0 && v.length <= 100,
    LONG_TEXT: (v: string): boolean => !!v && v.length > 0,
    URL: (v: string, trafficType: number, tracking: string): boolean | string => {
        if (trafficType === 2 && tracking === 'postback') {
            return !!v && v.length > 0 && v.includes('{transaction_id}') || 'Offer URL должен содержать transaction_id';
        }
        return !!v && v.length > 0;
    },
    NUMBER: (v: string): boolean => !!v && Number.isInteger(v) && Number(v) > 0,
    MONEY: (v: string | number): boolean => parseFloat(String(v)) >= 0,
    SELECT: (v: string | number | number[]): boolean => !!v && (typeof v === 'number' || (Array.isArray(v) && v.length > 0) || (typeof v === 'string' && v.length > 0)),
    SWITCH: (v: boolean): boolean => v !== undefined,
    DATE: (v: string): boolean => !!v && v.length > 0,
};

interface ISelectField {
    [key: string]: {
        data: IBaseAutocomplete,
        items?: any
        api?: () => Promise<any>
    }
}

const DEFAULT_TRACKING_DOMAIN = 'go.leadgid.com';

export class CreateOffer implements ICreateOffer {
    advertiser: number;
    approve_conversions: boolean;
    countries: string[];
    currency: string;
    deep_links_enabled: boolean;
    default_goal_name: string;
    departments: number;
    description: string;
    expiration_date: string;
    multiple_conversions: boolean;
    name: string;
    default_url: string;
    preview_url: string;
    payout_amount: number;
    payout_type: "fixed" | "percent";
    payouts: number;
    is_private: boolean;
    products: number[];
    ready_for_erir: boolean;
    requires_advertiser_approval: boolean;
    requires_approval: boolean;
    revenue_amount: number;
    revenue_type: "fixed" | "percent";
    session_hours: number;
    stats: number;
    tags: number[];
    target_actions: number[];
    tracking_protocol: string;
    tracking_domain: number;
    traffics: number;
    verticals: number;
    validationErrors: string[] = [];

    constructor(offer: ICreateOffer) {
        this.advertiser = offer.advertiser;
        this.approve_conversions = offer.approve_conversions || true;
        this.countries = offer.countries || [];
        this.currency = offer.currency;
        this.deep_links_enabled = offer.deep_links_enabled || false;
        this.default_goal_name = offer.default_goal_name;
        this.departments = offer.departments;
        this.description = offer.description;
        this.expiration_date = offer.expiration_date;
        this.multiple_conversions = offer.multiple_conversions || false;
        this.name = offer.name;
        this.payout_amount = offer.payout_amount || 0;
        this.payout_type = offer.payout_type || 'fixed';
        this.tracking_protocol = offer.tracking_protocol || 'postback';
        this.payouts = offer.payouts;
        this.is_private = offer.is_private || false;
        this.products = offer.products;
        this.ready_for_erir = false;
        this.requires_advertiser_approval = offer.requires_advertiser_approval || false;
        this.requires_approval = offer.requires_approval || false;
        this.revenue_amount = offer.revenue_amount || 0;
        this.revenue_type = offer.revenue_type || 'fixed';
        this.session_hours = offer.session_hours;
        this.stats = offer.stats;
        this.tags = offer.tags || [];
        this.target_actions = offer.target_actions;
        this.tracking_domain = offer.tracking_domain;
        this.traffics = offer.traffics;
        this.verticals = offer.verticals;
        this.default_url = offer.default_url;
        this.preview_url = offer.preview_url;
    }

    get getOptions(): {[key: string]: IOfferFiled[]} {
        const rules = CREATE_OFFER_VALIDATE_RULES;
        return {
            main: [
                { text: 'Название', property: 'name', type: OFFER_FIELD.TEXT, validateRule: rules.TEXT },
                { text: 'Рекламодатель', property: 'advertiser', type: OFFER_FIELD.AUTOCOMPLETE, validateRule: rules.SELECT, isWithDefVal: true },
                { text: 'Валюта', property: 'currency', type: OFFER_FIELD.AUTOCOMPLETE, validateRule: rules.SELECT, isWithDefVal: true },
                { text: 'Теги', property: 'tags', type: OFFER_FIELD.TAG_SELECT, isWithDefVal: true },
                { text: 'Срок действия', property: 'expiration_date', type: OFFER_FIELD.DATE, validateRule: rules.DATE },
                { text: 'ГЕО', property: 'countries', type: OFFER_FIELD.GEO, validateRule: rules.SELECT },
                { text: 'Приватность', property: 'is_private', type: OFFER_FIELD.SWITCH, validateRule: rules.SWITCH },
                { text: 'Требует подтверждения', property: 'requires_approval', type: OFFER_FIELD.SWITCH, validateRule: rules.SWITCH },
                { text: 'Требует согласования', property: 'requires_advertiser_approval', type: OFFER_FIELD.SWITCH, validateRule: rules.SWITCH },
            ],
            links: [
                { text: 'Offer URL', property: 'default_url', type: OFFER_FIELD.MULTILINE_TEXT, validateRule: (v) => rules.URL(v, this.traffics, this.tracking_protocol)  },
                { text: 'Preview URL', property: 'preview_url', type: OFFER_FIELD.MULTILINE_TEXT, validateRule: rules.LONG_TEXT },
            ],
            category: [
                { text: 'Вертикаль', property: 'verticals', type: OFFER_FIELD.SELECT, validateRule: rules.SELECT },
                { text: 'Направление', property: 'departments', type: OFFER_FIELD.SELECT, validateRule: rules.SELECT },
                { text: 'Тип трафика', property: 'traffics', type: OFFER_FIELD.SELECT, validateRule: rules.SELECT },
                { text: 'Тип продукта', property: 'products', type: OFFER_FIELD.AUTOCOMPLETE, validateRule: rules.SELECT, isWithDefVal: true },
                { text: 'Выплата', property: 'payouts', type: OFFER_FIELD.SELECT, validateRule: rules.SELECT },
                { text: 'Статистика', property: 'stats', type: OFFER_FIELD.SELECT, validateRule: rules.SELECT },
                { text: 'Целевое действие', property: 'target_actions', type: OFFER_FIELD.AUTOCOMPLETE, validateRule: rules.SELECT, isWithDefVal: true },
            ],
            tracking: [
                { text: 'Тип трекинга', property: 'tracking_protocol', type: OFFER_FIELD.SELECT, validateRule: rules.SELECT },
                { text: 'Трекинговый домен', property: 'tracking_domain', type: OFFER_FIELD.SELECT, validateRule: rules.SELECT },
                { text: 'Срок жизни кук', property: 'session_hours', type: OFFER_FIELD.COOKIES, validateRule: rules.NUMBER },
                { text: 'Количество часов', property: '', type: OFFER_FIELD.USER_COOKIES },
                { text: 'Автоподтверждение конверсий', property: 'approve_conversions', type: OFFER_FIELD.SWITCH, validateRule: rules.SWITCH },
                { text: 'Deeplink', property: 'deep_links_enabled', type: OFFER_FIELD.SWITCH, validateRule: rules.SWITCH },
                { text: 'Множественные конверсии', property: 'multiple_conversions', type: OFFER_FIELD.SWITCH, validateRule: rules.SWITCH },
            ],
            goal: [
                { text: 'Название', property: 'default_goal_name', type: OFFER_FIELD.TEXT, validateRule: rules.TEXT },
                { text: 'Приватность', property: 'is_private', type: OFFER_FIELD.SWITCH, validateRule: rules.SWITCH },
                { text: 'Доход', property: 'revenue_type', type: OFFER_FIELD.SELECT, validateRule: rules.SELECT },
                { text: '', property: 'revenue_amount', type: OFFER_FIELD.MONEY, validateRule: rules.MONEY },
                { text: 'Выплата', property: 'payout_type', type: OFFER_FIELD.SELECT, validateRule: rules.SELECT },
                { text: '', property: 'payout_amount', type: OFFER_FIELD.MONEY, validateRule: rules.MONEY },
            ],
        };
    }

    getSelectData: ISelectField  = {
        advertiser: {
            data: {
                apiMethod: getAdvertisersSearch,
                template: (item: IAdvertiser) => item.id + ' ' + item.name,
                itemValue: 'id',
            },
        },
        currency: {
            data: { itemText: 'code', itemValue: 'code' },
            items: store.getters['catalogueModule/GET_CURRENCIES'],
        },
        revenue_type: {
            data: { itemText: 'name', itemValue: 'id' },
            items: [ { name: 'Фикс', id: 'fixed' }, { name: 'Процент', id: 'percent' } ],
        },
        payout_type: {
            data: { itemText: 'name', itemValue: 'id' },
            items: [ { name: 'Фикс', id: 'fixed' }, { name: 'Процент', id: 'percent' } ],
        },
        verticals: {
            data: { itemText: 'name', itemValue: 'id' },
            api: getEmployeesOfferVerticals,
        },
        departments: {
            data: { itemText: 'name', itemValue: 'id' },
            api: getEmployeesDepartments,
        },
        traffics: {
            data: { itemText: 'name', itemValue: 'id' },
            api: getEmployeesTraffics,
        },
        products: {
            data: { itemText: 'name', itemValue: 'id', multiple: true },
            api: getEmployeesProducts,
        },
        payouts: {
            data: { itemText: 'name', itemValue: 'id' },
            api: getEmployeesPayouts,
        },
        stats: {
            data: { itemText: 'name', itemValue: 'id' },
            api: getEmployeesStats,
        },
        target_actions: {
            data: { itemText: 'name', itemValue: 'id', multiple: true },
            api: getEmployeesTargetActions,
        },
        tracking_domain: {
            data: { itemText: 'domain', itemValue: 'id' },
            api: getOfferTrackingDomainsList,
        },
        tracking_protocol: {
            data: { itemText: 'name', itemValue: 'type' },
            api: getOfferTrackingList,
        },
    };

    async getAllItems(): Promise<void> {
        await Promise.all(Object.values(this.getSelectData).map(async item => item.api && (item.items = await item.api())))
            .catch((err) => showServerError(err));
        this.setDefaultTrackingDomain();
    }

    setDefaultTrackingDomain(): void {
        const domains = this.getSelectData.tracking_domain.items;
        const domain = domains.find(i => i.domain === DEFAULT_TRACKING_DOMAIN);
        if (!domain) return;
        this.tracking_domain = domain.id;
    }

    get isDisableCreate(): boolean {
        const fields = Object.values(this.getOptions).flat();
        const validValues = fields.map(field =>
            field.validateRule === undefined ? true : field.validateRule(this[field.property]));
        this.validationErrors = validValues.filter(v => typeof v === 'string') as string[];
        const isValidFields = validValues.map(v => typeof v === 'boolean' ? v : false).every(v => v);
        return !(isValidFields && this.description.length > 0);
    }

    async createOffer(): Promise<void | number> {
        try {
            const dto = Object.fromEntries(Object.entries(this));
            const arrValues = ['verticals', 'departments', 'traffics', 'payouts', 'stats'];
            arrValues.forEach(i => dto[i] = [dto[i]]);
            delete dto.isLoading;
            delete dto.getSelectData;
            dto.tags.length === 0 && delete dto.tags;
            const { data } = await createEmployeesOffer(dto as ICreateOffer);
            showNotification('Оффер успешно создан');
            return data.id;
        } catch (err) {
            showServerError(err, 'Ошибка создания оффера');
        }
    }

    async prepareCopyData(copyId: string): Promise<void> {
        const data = await getOneEmployeesOffer(copyId);
        this.name = data.name;
        this.advertiser = data.advertiser.legacy_id;
        this.currency = data.goals[0].payout.currency;
        this.tags = data.tags.map(i => +i.id);
        this.countries = data.countries;
        this.is_private = !!data.private;
        this.ready_for_erir = false;
        this.requires_approval = !!data.confirmations?.requires_approval!;
        this.requires_advertiser_approval = !!data.confirmations?.requires_advertiser_approval!;
        this.verticals = data.verticals[0]?.id!;
        this.departments = data.departments[0]?.id!;
        this.traffics = data.traffics[0]?.id!;
        this.products = data.products.map(i => i.id);
        this.payouts = data.payouts[0]?.id!;
        this.stats = data.stats[0]?.id!;
        this.target_actions = data.target_actions.map(i => i.id);
        this.tracking_domain = data.tracking_domain?.id!;
        this.tracking_protocol = data.tracking_protocol?.type!;
        this.session_hours = data.session_hours;
        this.approve_conversions = data.approve_conversions;
        this.multiple_conversions = !!data.multiple_conversions;
        this.deep_links_enabled = !!data.deep_links_enabled;
        this.description = data.description;
        this.default_url = data.default_url;
        this.preview_url = data.preview_url;
    }
}
