import { render, staticRenderFns } from "./BaseAutocomplete.vue?vue&type=template&id=4c0af5b7&"
import script from "./BaseAutocomplete.vue?vue&type=script&lang=ts&"
export * from "./BaseAutocomplete.vue?vue&type=script&lang=ts&"
import style0 from "./BaseAutocomplete.vue?vue&type=style&index=0&id=4c0af5b7&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../.yarn/__virtual__/vue-loader-virtual-872bafd3cb/0/cache/vue-loader-npm-15.10.1-3a0b6c19d2-753a6b6da2.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports