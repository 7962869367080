
import { Component, Vue, Prop } from 'vue-property-decorator';
import BaseCard from "@/components/base/BaseCard.vue";
import TableWithFrontPaginationDesign from "@/components/base/table/TableWithFrontPaginationDesign.vue";
import { IUserV3 } from '@/api/types/user';
import { showNotification, showServerError } from "@/utils";
import { addToken, deleteUserActiveToken, getUserActiveToken } from "@/api/user";
import { PAGINATION_PER_PAGE } from "@/configs/global";

import SvgCopy from "@/assets/icons/copy.svg";
import SvgPlus from "@/assets/icons/plus.svg";
import SvgToken from "@/assets/icons/token.svg";
import { ACCOUNT_TYPE } from "@/mappings/account-type";

@Component({
    components: {
        SvgToken,
        SvgPlus,
        TableWithFrontPaginationDesign,
        BaseCard,
        SvgCopy,
    },
})
export default class UserTokenTab extends Vue {
    @Prop({ required: true }) user!: IUserV3;

    headers = [
        { text: 'Тип токена', sortable: false, value: 'type' },
        { text: 'ID', sortable: false, value: 'id', align: 'center' },
        { text: 'Токен', sortable: false, value: 'token', width: '310px', align: 'center' },
        { text: 'IP', sortable: false, value: 'ip', align: 'center' },
        { text: 'Действует до', sortable: false, value: 'expires_at', align: 'right' },
        { text: 'Дата создания', sortable: false, value: 'created_at', align: 'right' },
        { text: 'User-agent', sortable: false, value: 'user_agent' },
        { text: '', align: 'center', value: 'actions' },
    ];

    timeData = [
        {
            slot: 'item.created_at',
            key: 'created_at',
            isLine: false,
        },
        {
            slot: 'item.expires_at',
            key: 'expires_at',
            isLine: false,
        },
    ];

    deletingToken = {
        id: 0,
    };

    actions = [
        {
            title: 'Разлогинить',
            color: '#FFFFFF',
            icon: 'SvgUserLogout',
            isTooltip: true,
            callback: ({ id }: { id: number }): void => {
                this.deletingToken.id = id;
                this.logoutUser();
            },
        },
    ];

    uniqueData = [
        {
            slot: 'item.token',
        },
    ];

    activeTokens: any[] = [];
    loading = false;
    // pagination
    limit = PAGINATION_PER_PAGE;
    // встроенный Vuetify объект
    pagination: { page: number } = {
        page: 1,
    };
    selectedToken = 'api';

    get refs(): any {
        return this.$refs;
    }

    copyToken(token: string): void {
        const tempInput = document.createElement('input');
        tempInput.value = token;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand('copy');
        document.body.removeChild(tempInput);
        showNotification('Токен скопирован');
    }

    async addToken(): Promise<void> {
        try {
            const id = this.user.account_type === ACCOUNT_TYPE.ADVERTISER ? this.user.account_id : this.user.id;
            await addToken(id as number, this.user.account_type, this.selectedToken);
            await this.getActiveTokens();
        } catch (err) {
            showServerError(err, 'Список активных токенов не загружен');
        }
    }

    async getActiveTokens(offset?: number, limit?: number, isChangeLimit?: boolean): Promise<void> {
        if (offset === 0 || isChangeLimit) {
            this.pagination.page = 1;
        }
        if (limit) {
            this.limit = limit;
        }
        this.loading = true;
        try {
            const id = this.user.account_type === ACCOUNT_TYPE.ADVERTISER ? this.user.account_id : this.user.id;
            const { data } = await getUserActiveToken(id as number, this.$props.user.account_type, { offset, limit });
            this.activeTokens = data;
        } catch (err) {
            showServerError(err, 'Список активных токенов не загружен');
        }
        this.loading = false;
    }

    async logoutUser(): Promise<void> {
        this.loading = true;
        try {
            const id = this.user.account_type === ACCOUNT_TYPE.ADVERTISER ? this.user.account_id : this.user.id;
            const index = this.activeTokens!.findIndex((token) => token.id === this.deletingToken.id);
            await deleteUserActiveToken(id as number, this.user.account_type, this.deletingToken.id);
            this.activeTokens!.splice(index, 1);
            this.deletingToken.id = 0;
        } catch (err) {
            showServerError(err, 'Пользователь не разлогинен');
        }
        this.loading = false;
    }

    mounted(): void {
        this.getActiveTokens(0, this.limit);
    }

    async getTokens(offset?: number): Promise<void> {
        try {
            await this.getActiveTokens(offset ? offset : 0, this.limit);
        } catch (err) {
            showServerError(err, 'Ошибка при получении списка токенов');
        }
    }
}
