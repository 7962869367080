
import { Vue, Component, Prop } from "vue-property-decorator";
import BaseCard from "@/components/base/BaseCard.vue";
import { Affiliate } from "@/services/affiliates/Affiliate";
import {
    assignManager,
    changeAffiliateStatus,
    patchEmployeesOfferVerticals,
    patchEmployeesTrafficSource, patchAffiliateSettings,
    updateEmployeesAffiliate, verifiedAffiliateEmail, patchUserByTypeV3,
} from "@/api/user";
import { namespace } from "vuex-class";
import AffiliateAboutItem, { IAboutItem } from "@/components/affiliate/AffiliateAboutItem.vue";
import { showNotification, showServerError } from "@/utils";
import { addNewSlaveForMasterAccount, getPayoutMasterAccounts, makeNewMasterAccount } from "@/api/payout";
import { IMessenger } from "@/api/types/user";
import { getCurrentDate } from "@/utils/formatDates";

const offers = namespace('offersModule');

@Component({
    components: { AffiliateAboutItem, BaseCard },
})

export default class AffiliateAbout extends Vue {
    @Prop({ required: true }) affiliate!: Affiliate;
    @Prop({ required: true }) defaultAffiliate!: Affiliate;

    @offers.Getter('GET_VERTICALS') getVerticals!: {id: number, name: string}[];

    updatedUser: any = {};
    defaultMessengers: IMessenger[] = [];
    isLoading: boolean = false;
    isEdit: boolean = false;
    isUpdateAffiliate = false;
    isAlreadyVerifyEmail = false;
    validationFields = [
        { name: 'Телефон', isValid: true, fieldData : {} },
        { name: 'Фамилия', isValid: true, fieldData : {} },
        { name: 'Имя', isValid: true, fieldData : {} },
        { name: 'Никнейм', isValid: true, fieldData : {} },
        { name: 'Менеджер', isValid: true, fieldData : {} },
        { name: 'Мессенджер', isValid: true, fieldData : {} },
        { name: 'Телефон', isValid: true, fieldData : {} },
    ];

    get isChangedStatusAndManager():boolean {
        const status = this.getItems.find(i => i.type === 'status');
        const manager = this.getItems.find(i => i.type === 'manager');
        return (status?.value !== status?.newValue && manager?.value !== manager?.newValue!);
    }

    get getItems(): IAboutItem[] {
        return [
            {
                type: 'chip',
                name: 'Аккаунт: ID',
                value: [this.affiliate.id],
                isLink: true,
            },
            {
                type: 'master',
                subtype: 'chip',
                name: 'Аккаунт: Master ID',
                value: this.affiliate.is_master === 0 && this.affiliate.master_id === null ? [] : [this.affiliate.master_id],
                isMaster: !(this.affiliate.is_master === 0 && (this.affiliate.master_id === null) || this.affiliate.master_id === this.affiliate.id),
                isLink: !(this.affiliate.master_id === null && this.affiliate.is_master !== 0 ),
                isEditable: this.affiliate.master_id === null,
                editMethod: this.editMasterAccount,
            },
            {
                type: 'switch',
                name: 'Расширенный МА',
                value: this.affiliate.master_extension,
                isEditable: this.isEditableMasterExtension,
                editMethod: this.changeMasterExtension,
            },
            {
                type: 'switch',
                name: 'Мастер счета',
                value: this.affiliate.invoice_master,
                isEditable: this.isEditableInvoiceMaster,
                editMethod: this.changeInvoiceMaster,
            },
            {
                type: 'text',
                name: 'Фамилия',
                value: this.affiliate.last_name,
                isEditable: true,
                editMethod: (value: string) => this.$set(this.updatedUser, 'last_name', value),
            },
            {
                type: 'text',
                name: 'Имя',
                value: this.affiliate.first_name,
                isEditable: true,
                editMethod: (value: string) => this.$set(this.updatedUser, 'first_name', value),
            },
            {
                type: 'text',
                name: 'Никнейм',
                value: this.affiliate.nickname,
                isEditable: true,
                editMethod: (value: string) => this.$set(this.updatedUser, 'nick_name', value),
            },
            {
                type: 'status',
                name: 'Статус',
                value: this.affiliate.status,
                isEditable: true,
                editMethod: this.editStatus,
            },
            {
                type: 'manager',
                name: 'Менеджер',
                value: this.affiliate.manager?.id,
                managerData: this.affiliate.manager,
                isEditable: true,
                editMethod: this.editManager,
            },
            {
                type: 'date',
                name: 'Последний логин',
                value: this.affiliate.last_login?.created_at,
            },
            {
                type: 'date',
                name: 'Зарегистрирован',
                value: this.affiliate.signup_at,
            },
            {
                type: 'date',
                name: 'Создан',
                value: this.affiliate.created_at,
            },
            {
                type: 'date',
                name: 'Обновлён',
                value: this.affiliate.updated_at,
            },
            {
                type: 'messengers',
                name: 'Мессенджер',
                value: this.affiliate.messengers,
                isEditable: true,
                editMethod: (value: any) => this.$set(this.updatedUser, 'messengers', value),
            },
            {
                type: 'text',
                name: 'E-mail',
                value: this.affiliate.email,
                isVerified: this.affiliate.email_verified,
                hasButton: true,
                isDisabledBtn: !this.isAlreadyVerifyEmail,
                isEditable: false,
                btnMethod: this.verifiedEmail,
            },
            {
                type: 'phone',
                name: 'Телефон',
                value: this.affiliate.phone,
                isVerified: this.affiliate.phone_verified,
                isEditable: true,
                editMethod: this.editPhone,
            },
            {
                type: 'date',
                name: 'Дата рождения',
                value: this.affiliate.birthday,
                isWithoutTime: true,
                isEditable: true,
                editMethod: (value: string) => this.$set(this.updatedUser, 'birthday', value),
            },
            {
                type: 'country',
                name: 'Страна проживания',
                value: this.affiliate.country,
                isEditable: true,
                editMethod: (value: string) => this.$set(this.updatedUser, 'country', value),
            },
            {
                type: 'text',
                name: 'Город проживания',
                value: this.affiliate.city,
                isEditable: true,
                editMethod: (value: string) => this.$set(this.updatedUser, 'city', value),
            },
            {
                type: 'language',
                name: 'Кабинет: Язык',
                value: this.affiliate.language,
                isEditable: true,
                editMethod: (value: string) => this.$set(this.updatedUser, 'language', value),
            },
            {
                type: 'theme',
                name: 'Кабинет: Тема',
                value: this.affiliate.theme,
                isEditable: true,
                isBlockable: true,
                editMethod: this.editTheme,
            },
            {
                type: 'switch',
                name: 'Кабинет: Мар-ка EN',
                value: this.affiliate.open_erid,
                isEditable: true,
                editMethod: this.changeErid,
            },
            {
                type: 'switch',
                name: 'Кабинет: Досрочки',
                value: this.affiliate.advance_payments,
                isEditable: this.affiliate.master_id === null || this.affiliate.master_id === this.affiliate.id,
                editMethod: this.changePayment,
            },
            {
                type: 'vertical',
                name: 'Вертикаль',
                value: [...this.affiliate.verticals?.map((v) => v.id) || []],
                isEditable: true,
                isBlockable: true,
                isChip: true,
                editMethod: this.editVertical,
                isMultiple: true,
            },
            {
                type: 'traffic',
                name: 'Источники трафика',
                value: [...this.affiliate.getTrafficSources || []],
                isEditable: true,
                isBlockable: true,
                isChip: true,
                editMethod: this.editTrafficSource,
                isMultiple: true,
            },
            {
                type: 'tags',
                name: 'Теги',
                value: this.affiliate.tags?.map((i) => +i.id) || [],
                items: this.affiliate.tags,
                isEditable: true,
                isChip: true,
                editMethod: this.changeTags,
                isMultiple: true,
            },
        ];
    }

    async updateStatusAndManager(): Promise<void> {
        const status = this.getItems.find(i => i.type === 'status');
        const manager = this.getItems.find(i => i.type === 'manager');
        if (!manager || !status) return;
        await manager.editMethod!(manager.newValue);
        setTimeout(async () => {
            await status.editMethod!(status.newValue);
            this.affiliate.status = status.newValue;

        }, 1000);
    }

    async editFields(): Promise<void> {
        if (Array.isArray(this.$refs.item)) this.$refs.item.forEach((item) => (item as any).editValue());
        const fields = this.isChangedStatusAndManager ? this.getItems.filter(i => i.type !== 'status' && i.type !== 'manager') : this.getItems;
        for (const item of fields) {
            if (item.editMethod! && item.newValue !== item.value && item.isEditable) {
                try {
                    await item.editMethod(item.newValue);
                } catch (err) {
                    showServerError(err, `Ошибка сохранения: ${item.name || ''}`);
                } finally {
                    this.isUpdateAffiliate = true;
                }
            }
        }
    }

    async updateAllFields(): Promise<void> {
        try {
            this.isLoading = true;
            await this.editFields();
            await this.updateUser();
            if(this.isChangedStatusAndManager) await this.updateStatusAndManager();
            if (this.isUpdateAffiliate) this.$emit('update-affiliate');
        } catch (err) {
            showServerError(err, 'Ошибка сохранения');
            this.$emit('update-affiliate');
        } finally {
            this.isLoading = false;
        }
    }

    editInfo(): void {
        if (this.isEdit) {
            this.updateAllFields();
        } else {
            if (Array.isArray(this.$refs.item)) this.$refs.item.forEach((item) => (item as any).setValue());
            this.defaultMessengers = JSON.parse(JSON.stringify(this.defaultAffiliate.messengers));
        }
        this.isEdit = !this.isEdit;
    }

    cancelEdit(): void {
        this.isEdit = false;
        if (Array.isArray(this.$refs.item)) this.$refs.item.forEach((item) => (item as any).setValue());
    }

    get isEditableMasterExtension(): boolean {
        return this.affiliate.master_id === null || this.affiliate.master_id === this.affiliate.id;
    }

    get isEditableInvoiceMaster(): boolean {
        return this.isEditableMasterExtension && this.affiliate.master_extension === 'enabled';
    }

    get isEditDisabled(): boolean {
        return this.validationFields.some(field => !field.isValid);
    }

    async updateUser(): Promise<void> {
        const keysToAlwaysInclude = ['last_name', 'first_name'];
        const updatedProperties = {};
        for (const key of Object.keys(this.updatedUser)) {
            if (key === 'birthday') {
                if (this.defaultAffiliate.birthday !== this.updatedUser[key] && getCurrentDate('yyyy-MM-dd') !== this.updatedUser[key] && !!this.updatedUser[key])
                    updatedProperties[key] = this.updatedUser[key];
            }
            else if (key === 'nick_name') {
                if (this.updatedUser[key] !== this.defaultAffiliate.nickname) updatedProperties[key] = this.updatedUser[key];
            } else if (this.updatedUser[key] !== this.defaultAffiliate[key] && key !== 'messengers') {
                if (key !== 'country' || this.updatedUser[key] !== '') updatedProperties[key] = this.updatedUser[key];
            }
            if (key === 'messengers') {
                const selectedMessenger = this.validationFields.find(item => item.name === 'Мессенджер')?.fieldData as { type: string, account: string };
                const defaultMessenger = this.defaultMessengers.find(e => e.type === selectedMessenger?.type);

                if (!defaultMessenger || (defaultMessenger.account !== selectedMessenger?.account)) {
                    updatedProperties['messengers'] = [selectedMessenger];
                }

            }
        }
        keysToAlwaysInclude.forEach(i => updatedProperties[i] = this.updatedUser[i] || this.affiliate[i]);
        if (Object.keys(updatedProperties).length === keysToAlwaysInclude!.length) {
            const newValues = keysToAlwaysInclude!.filter((key) => (updatedProperties[key] !== this.defaultAffiliate[key] && updatedProperties[key]));
            if (newValues.length > 0) await updateEmployeesAffiliate(this.affiliate.id!, updatedProperties);
        } else if (Object.keys(updatedProperties).length > keysToAlwaysInclude.length) {
            await updateEmployeesAffiliate(this.affiliate.id!, updatedProperties);
        }
    }

    async changeMasterExtension(value: 'enabled' | 'disabled'): Promise<void> {
        if (value === 'disabled') await this.changeInvoiceMaster(value);
        await patchAffiliateSettings(this.affiliate.user_id!, { key: 'master_extension', value });
        showNotification('Настройки мастер-аккаунта сохранены');
    }

    async changeInvoiceMaster(value: 'enabled' | 'disabled'): Promise<void> {
        if (this.affiliate.invoice_master === value) return;
        await patchAffiliateSettings(this.affiliate.user_id!, { key: 'invoice_master', value });
        showNotification('Настройки мастер счёта сохранены');
    }

    async editStatus(status: string): Promise<void> {
        await changeAffiliateStatus(this.affiliate.id!, {
            status,
        });
        showNotification('Статус успешно изменён');
    }

    async editManager(manager_id: number): Promise<void> {
        const affiliate_id = this.affiliate.id!;
        await assignManager(affiliate_id, { manager_id });
        showNotification('Менеджер успешно изменён');
    }

    async editPhone(phone: string): Promise<void> {
        const accountId = this.affiliate.id!;
        const accountType = 'affiliate';
        await patchUserByTypeV3(accountId, accountType, { phone });
        showNotification('Телефон успешно изменён');
    }

    async editVertical(verticals: number[]): Promise<void> {
        if (JSON.stringify(this.affiliate.verticals.map(el => el.id).sort()) === JSON.stringify(verticals.sort())) return;
        await patchEmployeesOfferVerticals(this.affiliate.id!, { verticals });
        showNotification('Вертикали успешно изменены');
    }

    async editTheme(value: string): Promise<void> {
        if (value.length > 0) {
            await patchAffiliateSettings(this.affiliate.user_id!, { key: 'theme', value });
            showNotification('Тема успешно изменена');
        }
    }

    async editTrafficSource(ids: number[]): Promise<void> {
        if (JSON.stringify(this.affiliate.getTrafficSources.sort()) !== JSON.stringify(ids.sort())) {
            await patchEmployeesTrafficSource(this.affiliate.id!, { ids });
            showNotification('Источники трафика успешно изменены');
        }
    }

    changeErid(value: string): void {
        this.changeSwitch('open_erid', value);
    }

    changePayment(value: string): void {
        this.changeSwitch('advance_payments', value);
    }

    async changeSwitch(key: string, value: string): Promise<void> {
        if (!value || (this.affiliate[key] === value)) return;
        await patchAffiliateSettings(this.affiliate.user_id!, { key, value });
        showNotification('Изменения сохранены');
    }

    async editMasterAccount(masterId: number): Promise<void> {
        if (!masterId || masterId === this.affiliate.master_id || Array.isArray(masterId)) return;
        const params = {
            offset: 0,
            limit: 100,
            affiliate_ids: [masterId],
        };
        const masterAcc = await getPayoutMasterAccounts(params);
        if (masterAcc.data.length > 0) {
            const id = masterAcc.data[0].id;
            const data = { slaves: [this.affiliate.id!] };
            await addNewSlaveForMasterAccount(id, data);
            showNotification('Мастер аккаунт успешно обновлён');
        } else {
            const data = { master: { id: masterId }, slaves: [this.affiliate.id!] };
            await makeNewMasterAccount(data);
            showNotification('Мастер аккаунт успешно создан');
        }
    }

    async changeTags(tags: number[]): Promise<void> {
        const oldTags = this.affiliate.tags.map(i => +i.id);
        const isEqual = oldTags!.length === tags!.length && oldTags.every((el, i) => el === tags[i]);
        if (isEqual) return;
        await this.affiliate.saveTags({ tags });
    }

    async verifiedEmail(): Promise<void> {
        try {
            await verifiedAffiliateEmail({ affiliate_ids: [this.affiliate.id!], verified: true });
            showNotification('Партнёру отправлено письмо со ссылкой для подтверждения почты');
            this.isAlreadyVerifyEmail = true;
        } catch(e) {
            if (e.status === 404 && e.data?.error?.message?.includes('has an active link')) {
                showNotification('Партнёру уже отправлено письмо со ссылкой для подтверждения почты.');
            } else {
                showServerError(e, 'Ошибка верификации почты');
                this.isAlreadyVerifyEmail = false;
            }
        }
    }

    validationChanged(item: { name: string, isValid: boolean, fieldData?: any }): void {
        const field = this.validationFields.find(f => f.name === item.name);
        if (field) {
            field.isValid = item.isValid;
            field.fieldData = item.fieldData;
        }
        if( item.name === 'Мессенджер' ){
            this.affiliate.messengers = [item.fieldData];
        }
    }
}

