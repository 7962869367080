import { Module } from 'vuex';

import { actions } from '@/store/modules/administration/actions';
import { mutations } from '@/store/modules/administration/mutations';
import { getters } from '@/store/modules/administration/getters';

import { IRootState } from '@/store/types';
import { IAdministrationState } from "@/store/modules/administration/types";

export const state: IAdministrationState = {
    userData: {},
};

const namespaced = true;

export const administrationModule: Module<IAdministrationState, IRootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
