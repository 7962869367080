import { AxiosResponse } from 'axios';
import pick from 'lodash-es/pick';
import omit from 'lodash-es/omit';
import { axiosInstance } from '@/api/index';
import { IParamsObject, IServerResponse } from '@/api/types';
import { API_GATEWAY_URL, API_USERS_URL, API_USERS_URL_3 } from '@/configs/global';
import {
    IAffiliateItem,
    IAuthorization,
    ICreateAffiliate,
    IMessenger,
    INote,
    ITag,
    ITrafficSource,
    IUser,
    IUserActiveToken,
    IUserV3,
} from '@/api/types/user';
import { ITypes } from '@/api/types/revenue';
import { toBase64, toNumberedBoolean } from '@/utils';
import { ACCOUNT_TYPE } from "@/mappings/account-type";

/**
 *  Получение или экспорт списка партнеров
 */
export async function getEmployeesAffiliates(
    params?: IParamsObject,
    downLoadFormat?: 'xls' | 'csv' | '',
): Promise<IServerResponse<IAffiliateItem[]>> {
    // приведение boolean фильтров к 0 и 1
    if (params) {
        const revert = ['new_regs', 'slaves_old_ma', 'is_invoice_master'];
        const all= ['is_master', 'phone_verified', 'email_verified', 'has_verticals', ...revert];
        all.forEach((param) => {
            if (params[param] !== undefined) {
                params[param] = toNumberedBoolean(params[param]);
                if (revert.includes(param)) {
                    params[param] = toNumberedBoolean(!params[param]);
                }
            }
        });
    }
    const rawData = { ...omit(params, ['limit', 'offset']) };
    const data = Object.values(rawData).every(i => !i) ? undefined : rawData;
    let headers;
    let responseType;
    if (downLoadFormat === 'xls') {
        headers = { 'accept': "application/vnd.ms-excel;charset=utf-8" };
        responseType = 'blob';
        if (params) params.limit = 99999;
    }
    return axiosInstance.request<IServerResponse<IAffiliateItem[]>>({
        method: 'POST',
        headers: { ...headers },
        url: `${API_USERS_URL_3}/employees/affiliates`,
        params: {
            ...pick(params, ['limit', 'offset']),
        },
        data,
        responseType,
    })
        .then((response: AxiosResponse<IServerResponse<IAffiliateItem[]>>) => response.data);
}

/**
 * Создание партнёра
 */
export async function createEmployeeAffiliate(
    data: ICreateAffiliate,
): Promise<{ id: number }> {
    return axiosInstance.request<IServerResponse<{ id: number }>>({
        method: 'POST',
        url: `${API_USERS_URL_3}/employees/affiliates/create`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<{ id: number }>>) => response.data.data);
}

/**
 *  Получение партнера
 */
export async function getEmployeesAffiliate(
    affiliateAccountId: number | string,
): Promise<IAffiliateItem> {
    return axiosInstance.request<IServerResponse<IAffiliateItem>>({
        method: 'GET',
        url: `${API_USERS_URL_3}/employees/affiliates/${affiliateAccountId}`,
    })
        .then((response: AxiosResponse<IServerResponse<IAffiliateItem>>) => response.data.data);
}

/**
 *  Обновление данных партнера
 */
export async function updateEmployeesAffiliate(
    affiliateAccountId: number | string,
    data?: any,
): Promise<IAffiliateItem> {
    return axiosInstance.request<IServerResponse<IAffiliateItem>>({
        method: 'PATCH',
        url: `${API_USERS_URL_3}/employees/affiliates/${affiliateAccountId}`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<IAffiliateItem>>) => response.data.data);
}

/**
 *  Получаем список пользователей из калитки
 */
export async function getUsers(
    params: IParamsObject,
): Promise<IServerResponse<IUser[]>> {
    return axiosInstance.request<IServerResponse<IUser[]>>({
        method: 'GET',
        url: `${API_USERS_URL}/users`,
        params: {
            ...params,
        },
    })
        .then((response: AxiosResponse<IServerResponse<IUser[]>>) => response.data);
}

/**
 *  Получаем список пользователей из калитки
 */
export async function getUsersV3(
    params: IParamsObject,
): Promise<IServerResponse<IUser[]>> {
    const rawData = { ...omit(params, ['limit', 'offset']) };
    const data = Object.values(rawData).every(i => !i) ? undefined : rawData;
    return axiosInstance.request<IServerResponse<IUser[]>>({
        method: 'POST',
        url: `${API_USERS_URL_3}/employees/users`,
        params: {
            ...pick(params, ['limit', 'offset']),
        },
        data,
    })
        .then((response: AxiosResponse<IServerResponse<IUser[]>>) => response.data);
}

export async function getUserEntityV3(
    id: number | string,
): Promise<IUserV3> {
    return axiosInstance.request<IServerResponse<IUserV3>>({
        method: 'GET',
        url: `${API_USERS_URL_3}/employees/users/${id}`,
    })
        .then((response: AxiosResponse<IServerResponse<IUserV3>>) => response.data.data);
}

/*
 * Получение ролей юзера
 */
export async function getUserRoles(
    params: IParamsObject,
): Promise<IServerResponse<IUser[]>> {
    return axiosInstance.request<IServerResponse<IUser[]>>({
        method: 'GET',
        url: `${API_USERS_URL}/users/roles`,
        params: {
            ...params,
        },
    })
        .then((response: AxiosResponse<IServerResponse<IUser[]>>) => response.data);
}

/**
 *  Поиск менеджеров по `id` или `name`
 */

export async function getActiveUserSearch(
    params?: IParamsObject,
): Promise<IServerResponse<IUser[]>> {
    return getUserSearch({ ...params, statuses: ['active'] });
}

/**
 *  Поиск пользователей по `id` или `name`
 */
export async function getUserSearch(
    params?: IParamsObject,
): Promise<IServerResponse<IUser[]>> {
    const rawData = { ...omit(params, ['limit', 'offset']) };
    rawData!.search = params!.query;
    rawData.fields = ["id", "user_id", "first_name", "last_name", "email"];
    const data = Object.values(rawData).every(i => !i) ? undefined : rawData;
    return axiosInstance.request<IServerResponse<IUser[]>>({
        method: 'POST',
        url: `${API_USERS_URL_3}/employees/employees`,
        params: {
            ...omit(params, 'query'),
        },
        data,
    })
        .then((response: AxiosResponse<IServerResponse<IUser[]>>) => response.data);
}

/**
 *  Поиск партнеров по `id` или `name`
 */
export async function getAffiliateSearch(
    params?: IParamsObject,
): Promise<IServerResponse<IUser[]>> {
    const rawData: any = { ...omit(params, ['limit', 'offset', 'query']), show_is_deleted: 1 };
    rawData!.search = params!.query.toString();
    rawData.fields = ["id", "user_id", "first_name", "last_name", "email"];
    const data = Object.values(rawData).every(i => !i) ? undefined : rawData;
    return axiosInstance.request<IServerResponse<IUser[]>>({
        method: 'POST',
        url: `${API_USERS_URL_3}/employees/affiliates`,
        params: {
            ...omit(params, 'query'),
        },
        data,
    })
        .then((response: AxiosResponse<IServerResponse<IUser[]>>) => response.data);
}

/**
 *  Получение детальной информации пользователя
 */
export async function getUser(
    accountId: number,
    accountType: string,
): Promise<IUser> {
    return axiosInstance.request<IServerResponse<IUser>>({
        method: 'GET',
        url: `${API_USERS_URL}/users/${accountId}/${accountType}`,
    })
        .then((response: AxiosResponse<IServerResponse<IUser>>) => response.data.data);
}

/**
 *  Получение детальной информации пользователя
 */
export async function getUserV3(
    id: number | string,
    accountType: 'employee' | 'affiliate',
): Promise<IUserV3> {
    return axiosInstance.request<IServerResponse<IUserV3>>({
        method: 'GET',
        url: `${API_USERS_URL_3}/employees/${accountType}s/${id}`,
    })
        .then((response: AxiosResponse<IServerResponse<IUserV3>>) => response.data.data);
}

export async function geAffiliateChangeHistory(
    account_id: number | string,
): Promise<IServerResponse<IUser>> {
    return axiosInstance.request<IServerResponse<IUser>>({
        method: 'GET',
        url: `${API_USERS_URL_3}/employees/affiliates/${account_id}/changes`,
        params: { limit: 500 },
    })
        .then((response: AxiosResponse<IServerResponse<IUser>>) => response.data);
}

export async function patchUserByTypeV3(
    id: number | string,
    userType: 'advertiser' | 'employee' | 'affiliate' | 'system-user' | 'system',
    data: {
        roles?: string[];
        image?: File | null | string;
        remove_image?: boolean | null;
        messengers?: IMessenger[];
        reset_code?: boolean | null;
        phone?: string;
        first_name?: string;
        last_name?: string;
        status?: string;
        settings?: {
            key: string;
            value: string;
        }[];
    },
): Promise<IUser> {
    if (data.image) {
        data.image = await toBase64(data.image as File);
    }
    return axiosInstance.request<IServerResponse<IUser>>({
        method: 'PATCH',
        url: `${API_USERS_URL_3}/employees/${userType === 'system' ? 'system-user' : userType}s/${id}`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<IUser>>) => response.data.data);
}

/**
 * Метод подтверждения почты партнёра
 */
export async function verifiedAffiliateEmail(
    data: {
        affiliate_ids: number[],
        verified: boolean
    },
): Promise<any> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'PATCH',
        url: `${API_USERS_URL_3}/employees/affiliates/verified`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data.data);
}


/**
 *  Метод назначения менеджера
 */
export async function assignManager(
    affiliate_id: number,
    data: {
        manager_id: number,
    },
): Promise<IUser> {
    return axiosInstance.request<IServerResponse<IUser>>({
        method: 'PATCH',
        url: `${API_USERS_URL_3}/employees/affiliates/${affiliate_id}/manager`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<IUser>>) => response.data.data);
}


/**
 *  Метод удаления менеджера
 */
export async function deleteManager(
    data: {
        affiliate_id: number,
    },
): Promise<IUser> {
    return axiosInstance.request<IServerResponse<IUser>>({
        method: 'DELETE',
        url: `${API_USERS_URL}/users/remove-manager`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<IUser>>) => response.data.data);
}

/**
 *  Метод изменения настроек партнера
 */
export async function patchAffiliateSettings(
    user_id: number,
    data: {key: string, value: string},
): Promise<any> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'PATCH',
        url: `${API_USERS_URL}/profile/settings/${user_id}`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data.data);
}

/**
 *  Изменение вертикалей у партнера
 */
export async function patchEmployeesOfferVerticals(
    id: string | number,
    data?: any,
): Promise<any> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'patch',
        url: `${API_USERS_URL}/employees/affiliates/${id}/verticals`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data.data);
}


/**
 *  Изменение трафика у партнера
 */
export async function patchEmployeesTrafficSource(
    id: string | number,
    data?: any,
): Promise<any> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'patch',
        url: `${API_USERS_URL}/employees/affiliates/${id}/traffic-sources`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data.data);
}


/**
 *  Изменение статуса партнера
 */
export async function changeAffiliateStatus(
    accountId: number,
    data: {
        status: string;
    },
): Promise<any> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'PATCH',
        url: `${API_USERS_URL_3}/employees/affiliates/${accountId}/status`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data.data);
}

/**
 * Массовое изменение статусов партнёров
 */

export async function massChangeAffiliatesStatus(
    data: {
        affiliate_ids: number[],
        status: string
    },
): Promise<IServerResponse<any>> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'PATCH',
        url: `${API_USERS_URL_3}/employees/affiliates/status`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data.data);
}

/**
 *  Включить/отключить 2fa юзеру CRM
 */
export async function editUserSettings(
    userId: number,
    accountType: string,
    data: {
        is_two_factor_enable: boolean,
        two_factor_code?: string,
    },
): Promise<any> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'PATCH',
        url: `${API_USERS_URL}/users/${userId}/${accountType}/settings`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data.data);
}

/**
 *  Получение активных токенов юзера
 */
export async function getUserActiveToken(
    id: number,
    accountType: string,
    params: {},
): Promise<IServerResponse<IUserActiveToken[]>> {
    return axiosInstance.request<IServerResponse<IUserActiveToken[]>>({
        method: 'GET',
        url: `${API_USERS_URL}/users/${id}/${accountType}/tokens`,
        params,
    })
        .then((response: AxiosResponse<IServerResponse<IUserActiveToken[]>>) => response.data);
}

/**
 *  Удаление активного токена
 */
export async function deleteUserActiveToken(
    id: number,
    accountType: string,
    tokenId: number,
): Promise<object> {
    return axiosInstance.request<object>({
        method: 'DELETE',
        url: `${API_USERS_URL}/users/${id}/${accountType}/tokens/${tokenId}`,
    });
}

/**
 *  Получение всех возможных типов пользователей
 */
export async function getUserTypes(
): Promise<ITypes> {
    return axiosInstance.request<IServerResponse<ITypes>>({
        method: 'GET',
        url: `${API_USERS_URL}/users/types`,

    })
        .then((response: AxiosResponse<IServerResponse<ITypes>>) => response.data.data);
}

/**
 *  Создание пользователя
 */
export async function createUser(
    data: IUser,
): Promise<IUser> {
    return axiosInstance.request<IServerResponse<IUser>>({
        method: 'POST',
        url: `${API_USERS_URL}/users`,
        data,

    })
        .then((response: AxiosResponse<IServerResponse<IUser>>) => response.data.data);
}

/**
 *  Выдать токен
 */
export async function addToken(
    id: number,
    accountType: ACCOUNT_TYPE,
    tokenType: string,
): Promise<IUserActiveToken> {
    return axiosInstance.request<IServerResponse<IUserActiveToken>>({
        method: 'POST',
        url: `${API_USERS_URL}/users/${id}/${accountType}/tokens`,
        data: {
            token_type: tokenType,
        },

    })
        .then((response: AxiosResponse<IServerResponse<IUserActiveToken>>) => response.data.data);
}

export async function getAffiliateLoginURL(
    affiliateAccountId: number,
): Promise<any> {
    return axiosInstance.request({
        method: 'POST',
        url: `${API_GATEWAY_URL}/login-affiliate`,
        data: { affiliateAccountId },
    })
        .then((response) => response.data.data);
}

/**
 *  Получение affiliate_traffic_source_id
 */
export async function getTrafficSource(
): Promise<ITrafficSource[]> {
    return axiosInstance.request<IServerResponse<ITrafficSource[]>>({
        method: 'GET',
        url: `${API_USERS_URL}/employees/traffic-sources`,

    })
        .then((response: AxiosResponse<IServerResponse<ITrafficSource[]>>) => response.data.data);
}

/**
 * Получение списка заметок по пользователю
 */
export async function getUserNotes(
    params: IParamsObject,
): Promise<IServerResponse<INote[]>> {
    return axiosInstance.request<IServerResponse<INote[]>>({
        method: 'GET',
        url: `${API_USERS_URL}/notes`,
        params: {
            ...params,
        },
    })
        .then((response: AxiosResponse<IServerResponse<INote[]>>) => response.data);
}

/**
 * Создание заметки
 */
export async function createUserNote(
    note: {note: string, image: string | null},
    affiliate_id: number,
): Promise<IServerResponse<{id: number}>> {
    return axiosInstance.request<IServerResponse<{id: number}>>({
        method: 'POST',
        url: `${API_USERS_URL}/notes`,
        data: { ...note, affiliate_id },
    })
        .then((response: AxiosResponse<IServerResponse<{id: number}>>) => response.data);
}

/**
 * Редактирование заметки
 */
export async function editUserNote(
    note: {note: string, image: string | null},
    id: number | string,
): Promise<any> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'POST',
        url: `${API_USERS_URL}/notes/${id}`,
        data: note,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data.data);
}

/**
 *  Удаление заметки
 */
export async function deleteUserNote(
    id: string | number,
): Promise<IServerResponse<any>> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'DELETE',
        url: `${API_USERS_URL}/notes/${id}`,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data.data);
}

/**
 * Получение списка авторизаций пользователя
 */
export async function getUserAuthorizations(
    id: string | number,
): Promise<IServerResponse<IAuthorization[]>> {
    return axiosInstance.request<IServerResponse<IAuthorization[]>>({
        method: 'GET',
        url: `${API_USERS_URL}/employees/affiliate-tokens/${id}`,
    })
        .then((response: AxiosResponse<IServerResponse<IAuthorization[]>>) => response.data);
}

/**
 * Закрытие авторизаций пользователя
 */
export async function endUserSessions(
    id: number | string,
    token_ids: number[],
): Promise<any> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'PATCH',
        url: `${API_USERS_URL}/employees/affiliate-tokens/${id}`,
        data: { token_ids },
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data.data);
}

/**
 * Массовое назначение менеджера
 */
export async function batchAssignManager(
    data: {
        affiliate_ids: number[],
        manager_id: number,
    },
): Promise<any> {
    return axiosInstance.request<IServerResponse<any>>({
        method: 'PATCH',
        url: `${API_USERS_URL_3}/employees/affiliates/manager`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<any>>) => response.data.data);
}

/**
 * Получение списка существующих тегов
 */
export async function getAffiliateTags():Promise<ITag[]> {
    return axiosInstance.request<IServerResponse<ITag[]>>({
        method: 'GET',
        url: `${API_USERS_URL_3}/employees/tags`,
    })
        .then((response: AxiosResponse<IServerResponse<ITag[]>>) => response.data.data);
}

/**
 * Создание нового тега
 */
export async function createAffiliateTag(data: {name: string}):Promise<IServerResponse<any>> {
    return axiosInstance.request<IServerResponse<any>>({
        method: "POST",
        url: `${API_USERS_URL_3}/employees/tags`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<any[]>>) => response.data);
}


/**
 * Сохранение тегов партнёра
 */
export async function saveAffiliateTags(data: {tags: number[]}, affiliate_id: number):Promise<IServerResponse<any>> {
    return axiosInstance.request<IServerResponse<any>>({
        method: "PATCH",
        url: `${API_USERS_URL_3}/employees/affiliates/${affiliate_id}/tags`,
        data,
    })
        .then((response: AxiosResponse<IServerResponse<any[]>>) => response.data);
}
