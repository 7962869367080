
import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import Status from '@/components/base/Status.vue';
import AutocompleteListItem from '@/components/base/form/AutocompleteListItem.vue';
import { showServerError } from '@/utils';
import { SEARCH_VALUE_DEBOUNCE } from '@/configs/global';
import SvgExpandMore from '@/assets/icons/expand-more.svg';
import TrashBtn from "@/components/base/buttons/TrashBtn.vue";
import uniqBy from "lodash-es/uniqBy";
import { eventBus } from "@/eventbus";
import { IBaseAutocomplete } from "@/types";
import BaseCard from "@/components/base/BaseCard.vue";
import SvgPlus from "@/assets/icons/plus.svg";
import SvgArrow from "@/assets/icons/arrow-down.svg";
import uniq from "lodash-es/uniq";

interface ISelectItem {
    name: string;
    value: number | string;
    additional?: string;
}

@Component({
    components: {
        SvgArrow,
        BaseCard,
        TrashBtn,
        Status,
        AutocompleteListItem,
        SvgExpandMore,
        SvgPlus,
    },
})
export default class BaseAutocomplete extends Vue {
    @Prop({ required: true }) data!: IBaseAutocomplete;
    @Prop({ default: false }) isTrash!: boolean;
    @Prop({ default: false }) isShowUnfounded!: boolean;
    @Prop({ default: false }) noNeedTrash!: boolean;
    @Prop({ default: false }) isReturnObject!: boolean;
    @Prop({ default: false }) isAutoSelectFirst!: boolean;
    @Prop({ default: '' }) defaultSearch!: string | number | string[];
    @Prop({ default: '' }) alsoSearchBy!: string;
    @Prop({ default: 1 }) minSearchLength!: number;
    @Prop({ default: 'Превышен лимит партнёров' }) limitText!: string;
    @Prop({ default: '' }) createBtnText!: string;
    @Prop({ default: false }) isCreateMode!: boolean;
    @Prop({ default: false }) isOutlined!: boolean;
    @Prop({ default: false }) isHideValue!: boolean;
    @Prop({ default: 100 }) limitMultiPaste!: number | null;
    @PropSync('possibleItems', { type: Array, default: () => [], required: false }) possibleItemsSync!: any[];

    search: any = '';
    isLoading = false;
    searchTimerId = 0;
    items: ISelectItem[] = [];
    rawItems: any[] = [];
    model: any | any[] = [];
    modelCopy: any | any[] = [];
    notFoundIds: string[] = [];
    isShowModal: boolean = false;
    isLimitSearchItems = false;
    unFindedSearch: string[] = [];
    searchCollection: number = 0;
    isDefaultValueWasSet: boolean = false;

    get textFoundItems(): string {
        return `Найдено ${this.model.length} ` + (this.searchCollection > 1 ? `из ${this.searchCollection}` : '');
    }

    blurHandler(): void {
        const val = this.$refs.input!['lazySearch'];
        this.$emit('blur', val, this.model);
    }

    searchHandler(search: string): void {
        if (this.data.apiMethod === undefined || !search) return;
        this.search = search;
        clearTimeout(this.searchTimerId);
        this.searchTimerId = window.setTimeout(async () => {
            const collection: string[] = this.search?.trim().split(/[ ,]+/);
            const emailRegex = new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$');
            const isCollectionOfEmails = collection.every(item => emailRegex.test(item));
            const isCollectionOfNumbers = collection.every((item) => !isNaN(Number(item)));
            const isMultiPaste = this.data.multiPaste && collection?.length > 1 && (isCollectionOfNumbers || isCollectionOfEmails);

            if (isMultiPaste) {
                await this.multipleSearch(collection);
                this.searchCollection = this.searchCollection + collection?.length;
                this.changeItem();
            } else {
                const value: string = this.search?.toString().trim();
                await this.singleSearch(value);
            }
        }, SEARCH_VALUE_DEBOUNCE);
    }

    async multipleSearch(collection: string[]): Promise<void> {
        this.items = [];
        collection = this.limitMultiPaste ? collection.filter(Boolean).slice(0, this.limitMultiPaste - 1) : collection.filter(Boolean);
        for (const value of collection) {
            await this.getSelectItems(value);
            this.setMultipasteValue(value);
        }
        this.search = '';
        if (this.notFoundIds.length > 0 || (this.limitMultiPaste && (collection.length > this.limitMultiPaste))) {
            this.isShowModal = true;
        }
        this.isLimitSearchItems = this.limitMultiPaste ? collection.length > this.limitMultiPaste : false;
    }

    setMultipasteValue(value: string): void {
        const isNumber = !isNaN(Number(value));
        let items: ISelectItem[] = JSON.parse(JSON.stringify(this.items));
        if (items.length > 1) {
            const key = isNumber ? 'value' : 'name';
            items = items.filter(item => String(item[key]).includes(value) || item.additional?.includes(value));
        }
        if (items.length === 1 && this.data.multiPaste) {
            const select = [...this.model, items[0]];
            this.items = this.model = uniqBy(select, 'value');
        }
        if (items.length === 0) {
            this.notFoundIds = this.notFoundIds.includes(value) ? this.notFoundIds : [...this.notFoundIds, value];
            this.unFindedSearch = uniq([...this.unFindedSearch, value]);
        }
    }

    async singleSearch(value: string): Promise<void> {
        if (this.model === null) {
            this.model = this.data.multiple ? [] : '';
        }
        if (value.length >= this.minSearchLength) {
            await this.getSelectItems(value);
            if (this.defaultSearch.toString().length > 0 && !this.isNotEmptyModel && !this.isDefaultValueWasSet) {
                this.isDefaultValueWasSet = true;
                this.model = this.data.multiple ? this.items : this.items[0];
                this.changeItem();
            }
        } else {
            this.isLoading = false;
        }
    }

    async getSelectItems(value: string): Promise<void> {
        let params: any = {};

        if (this.data.externalApiParams) {
            params = this.data.externalApiParams(value);
        } else {
            params = this.data!.key! ? { [this.data.key]: value } : { query: value };
        }

        try {
            this.isLoading = true;
            const { data } = await this.data.apiMethod!(params);
            this.rawItems = [...this.rawItems,...data];
            const items = this.formattingItems(data);
            this.items = [...this.items, ...items];
        } catch (err) {
            showServerError(err, this.data.errorText);
        } finally {
            this.isLoading = false;
        }
    }

    formattingItems(items: any[]): ISelectItem[] {
        return items.map((item: any) => {
            const name = this.data.itemText !== undefined ? item[this.data.itemText] : this.data.template!(item);
            const value = item[this.data.itemValue];
            if (!this.alsoSearchBy) return { name, value };

            const additional = item[this.alsoSearchBy];
            return { name, value, additional };
        });
    }

    get getItemsForAutocomplete(): ISelectItem[] {
        return this.possibleItemsSync.length > 0 ? this.formattingItems(this.possibleItemsSync) : this.items;
    }

    get isNotEmptyModel(): boolean {
        return this.model && (this.model.length > 0 || Object.keys(this.model)?.length > 0);
    }

    get maxShowChips(): number {
        return this.data.maxShowItems ? this.data.maxShowItems : 3;
    }

    get getModalText(): string {
        const ids = this.notFoundIds.join(', ');
        return `Не найдены: ${ids}`;
    }

    get isHideNoDataText(): boolean {
        return this.model?.length > 0 ? this.model.length >= this.items.length : false;
    }

    get showPlaceholder(): string {
        return this.$props.data.placeholder && (!this.model || this.model?.length <= 0)  ? this.$props.data.placeholder : '';
    }

    clearModal(): void {
        this.isShowModal = false;
        this.isLimitSearchItems = false;
        this.notFoundIds = [];
    }

    clearModel(): void {
        if (this.model?.length > 0 || Object.keys(this.model)?.length > 0) {
            this.model = this.data.multiple ? [] : '';
            this.unFindedSearch = [];
            this.searchCollection = 0;
            this.items = [];
            this.$emit('change', this.model);
        }
        this.items = [];
    }

    created(): void {
        this.model = this.data.multiple ? [] : '';
        this.rawItems = this.possibleItemsSync;
        this.setDefaultSearch();
        this.setAutoSelectFirst();
        // слушаем событие сбросить все компонента
        eventBus.$on('clear', this.clearModel);
    }

    changeItem(): void {
        let value = '';
        if (!!this.model) {
            let rawValues = this.data.multiple ?
                this.model.map((i: ISelectItem) => i.value) : [this.model.value];
            if (this.isReturnObject) {
                rawValues = rawValues.map((i: string | number) => this.rawItems.find(item => item[this.data.itemValue] === i));
            }
            value = this.data.multiple ? rawValues : rawValues[0];
        }
        this.$emit('change', value);
        this.search = '';
    }

    @Watch('defaultSearch')
    setDefaultSearch(): void {
        if (!(!!this.defaultSearch || !!this.defaultSearch[0])) return;
        if (this.possibleItemsSync.length > 0) {
            const value = (Array.isArray(this.defaultSearch)
                ? this.defaultSearch.map(i => this.getItemsForAutocomplete.find(item => this.isDesiredItem(item, i)))
                : this.getItemsForAutocomplete.find(item => this.isDesiredItem(item, String(this.defaultSearch))));
            this.model = value;
            const valueToEmit = this.isReturnObject ? value : this.defaultSearch;
            this.$emit('change', valueToEmit);
        } else {
            if (!this.defaultSearch) return;
            const search = this.defaultSearch.toString().replaceAll(',', ' ');
            this.searchHandler(search);
        }
    }

    @Watch('possibleItemsSync')
    setRawItems(): void {
        this.rawItems = this.possibleItemsSync;
        this.setDefaultSearch();
    }

    isDesiredItem(item: ISelectItem, value: string | number): boolean {
        return item.name.includes(String(value)) || String(item.value).includes(String(value));
    }

    setAutoSelectFirst(): void {
        if (!this.isAutoSelectFirst || !this.getItemsForAutocomplete[0]) return;
        this.model = this.getItemsForAutocomplete[0];
        this.changeItem();
    }

    filterItems(item: ISelectItem, queryText: string): boolean {
        const textOne = item.name.toLowerCase();
        const searchText = queryText.toLowerCase();
        if (!this.alsoSearchBy) return textOne.indexOf(searchText) > -1;
        const textTwo = item.additional?.toLowerCase();
        return textOne.indexOf(searchText) > -1 || textTwo!.indexOf(searchText) > -1;
    }

    deleteItem(index: number): void {
        this.model.splice(index, 1);
        this.changeItem();
    }

    clearChip(): void {
        this.model = '';
        this.$emit('change', '');
    }

    beforeDestroy(): void {
        // слушаем событие сбросить все компонента
        eventBus.$off('clear', this.clearModel);
    }

    createItem(): void {
        this.$refs.input!['isFocused'] = false;
        this.$emit('open-create-modal');
    }
}
